import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import GenderPicker from '../components/Gender'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import Replay5 from '@mui/icons-material/Replay5';
import { navigate } from "gatsby";
import ThreeCol from '../components/ThreeColLayout'
const { useEffect } = React;




const IndexPage = () => {
  const [value, setValue] = React.useState(
    dayjs('1988-09-14T12:00:01'),
  );

  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };



  useEffect(() => {
    if (elements) {
      const accordionData = [
        {
          title: 'Five Element Health Analysis',
          content: <>
            <Box display="flex" flexDirection={"row"}>
              <Box width="50%">
                {
                  Object.keys(elements).length && Object.keys(elements).map(k => <Box display="flex">
                    <span style={{ textTransform: 'capitalize' }}>{k}</span>: {
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        {" "}
                        {
                          <Box display="flex" flexDirection={"row"} gap="6px" marginLeft="6px">
                            {
                              [...Array(elements[k] || 0).keys()].map(e => <Chip size="16px" num={k}>
                              </Chip>)
                            }
                          </Box>
                        }
                      </Stack>
                    }
                  </Box>)
                }
              </Box>
              <Box width="50%" display="flex" justifyContent={"center"} alignItems={"center"}>

              </Box>
            </Box>
            <Divider />

            <Box display="flex" justifyContent={"center"}>
              <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                <h3>Free In-depth Five Element Analysis</h3>


                <Fab color="primary" variant="extended" onClick={() => { navigate('product') }}>
                  <Replay5 sx={{ mr: 1 }} />
                  Five Element Analysis
                </Fab>
              </Box>

            </Box>
          </>
        },
      ]

      setAccordion(accordionData)
    }
  }, [elements])


  return (
    <>
      <Seo title="Home" />

      <Box display="flex" justifyContent="center" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ textAlign: 'center' }} justifyContent="center"><h1> Life Number</h1></Box>
          <Box display="flex" justifyContent="center" flexDirection="row">
            <DatePicker onDate={e => {
              const { day, month, year } = e;
              console.log(e)
              setDate(day.toString())
              setMonth(month.toString())
              setYear(year.toString())
            }} />
            <GenderPicker />
          </Box>

        </LocalizationProvider>

      </Box>

      <ChartLayout
        accordions={accordion}
      >
        <LifeNumber
          onNumber={(numbers) => {
            setElements(numbers?.elements)
          }}
          birthday={{
            day: `${day.padStart(2, '0')}`,
            month: `${month.padStart(2, '0')}`,
            year: `${year.padStart(4, '0')}`,
          }}
        />
      </ChartLayout>


    </>
  )
}

export default IndexPage
